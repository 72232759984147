<script setup>
import { useForm } from '@inertiajs/vue3';
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';

// Components
import Pagination from '@/Components/Pagination/PaginationFE.vue';
import Filters from '@/Components/Table/Filters.vue';
import Table from '@/Components/Table/Table.vue';

// Layouts
import StaffLayout from '@/Layouts/StaffLayout.vue';
import Button from '@/Components/Button/Button.vue';

const { t } = useI18n();
const route = inject('route');

const props = defineProps({
    query: Object,
    locations: Object,
});

const form = useForm({
    page: props.query.page ?? 1,
    orderBy: props.query.orderBy ?? '',
    orderDir: props.query.orderDir ?? 'desc',
});

const typeFilters = {};

const getData = (resetPage = true) => {
    form.page = resetPage ? 1 : form.page;

    form.get(route('staff.locations.index'), {
        preserveState: true,
        preserveScroll: true,
        only: ['locations', 'query'],
    });
};

const tableSettings = [
    { label: t('Name'), orderBy: 'name', width: '20%' },
    { label: t('Company'), orderBy: 'company', width: '15%' },
    { label: t('Owner'), width: '10%' },
    { label: t('Administration e-mail address'), from: 'md', width: '20%' },
    { label: t('Phone number'), width: '10%' },
    { label: '', cell: 'Arrow', width: '5%' },
];

const tableData = computed(() =>
    props.locations.data.map((location) => {
        return {
            id: location.id, // used as vue key if set
            rowData: [
                location.name + ', ' + location.address.city,
                location.company.commercial_name,
                location.owner_name,
                location.administration_email,
                location.phone_number,
                route('staff.locations.edit', location.id),
            ],
        };
    })
);

const setPage = (e) => {
    form.page = e;
    getData(false);
};
</script>

<template>
    <StaffLayout :title="$t('Locations')">
        <Filters
            :form="form"
            @anyUpdate="getData()"
            :h1="$t('Locations')"
            :data="locations"
            :typeFilters="typeFilters"
            :hasSearch="false"
        >
            <Button
                blue
                icon="/icon/download-excel.svg"
                type="button"
                asA
                class="ml-2"
                :href="route('staff.location-data.download')"
                :text="$t('Export')"
            />
        </Filters>
        <template v-if="typeof locations !== 'undefined' && locations.data.length > 0">
            <Table
                class="mb-8"
                :loading="form.processing"
                :tableSettings="tableSettings"
                :data="tableData"
                :footerFaded="query.search != '' && query.search != null"
                v-model:orderBy="form.orderBy"
                @update:orderBy="getData()"
                v-model:orderDir="form.orderDir"
                @update:orderDir="getData()"
            />

            <Pagination
                v-if="typeof locations !== 'undefined'"
                :links="locations.links"
                @setPage="setPage"
                :pageData="locations"
                :hideTotal="query.search != '' && query.search != null"
            />
        </template>
        <template v-else>
            <div class="w-full px-4 py-3 overflow-hidden font-bold leading-tight bg-white rounded">
                {{ $t('No {model} found', { model: $t('locations') }) }}
            </div>
        </template>
    </StaffLayout>
</template>
